import React, { useState, useEffect } from "react";
import Productbox from "../../Components/Productbox/Productbox";
import "./Products.css";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading/Loading";
import { Headchanger } from "../../utils/Headchanger";

const Products = () => {
  const [currentCategoryId, setCurrentCategoryId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const { t, i18n } = useTranslation();

  const [dataCategories, setDataCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [dataProducts, setDataProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);

  let currentLanguage = 1;
  if (i18n.language === "az") {
    currentLanguage = 1;
  } else if (i18n.language === "ru") {
    currentLanguage = 2;
  }

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  Headchanger("Foodini - " + t("products"), t("products"));

  useEffect(() => {
    fetch(`https://foodini.az/api/Category/GetAllCategories`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response error");
        }
        return response.json();
      })
      .then((responedata) => {
        setDataCategories(responedata.data);
        setLoadingCategories(false);
      })
      .catch((error) => {
        console.error(error.mesagge);
        setLoadingCategories(false);
      });
  }, []);

  useEffect(() => {
    fetch(`https://foodini.az/api/Product/GetAllProducts`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response error");
        }
        return response.json();
      })
      .then((responedata) => {
        setDataProducts(responedata.data);
        setLoadingProducts(false);
      })
      .catch((error) => {
        console.error(error.mesagge);
        setLoadingProducts(false);
      });
  }, []);

  const handleCategory = (category) => {
    setCurrentPage(1);
    setCurrentCategoryId(category.currentTarget.id);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const filteredProducts =
    Number(currentCategoryId) === 0
      ? dataProducts
      : dataProducts.filter((product) => {
          return product.category.id === Number(currentCategoryId);
        });

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const maxPagesToShow = 5;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredProducts.slice(startIndex, endIndex);

  const renderPageNumbers = () => {
    const pages = [];
    let startPage = currentPage - 2;
    let endPage = currentPage + 2;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(maxPagesToShow, totalPages);
    } else if (endPage > totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
      endPage = totalPages;
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </button>
      );
    }

    return (
      <>
        {startPage !== 1 && (
          <>
            <button onClick={() => handlePageChange(1)}>1</button>
            {startPage > 2 && <span>...</span>}
          </>
        )}
        {pages}
        {endPage !== totalPages && (
          <>
            {endPage < totalPages - 1 && <span>...</span>}
            <button onClick={() => handlePageChange(totalPages)}>
              {totalPages}
            </button>
          </>
        )}
      </>
    );
  };

  return (
    <div className="products_page">
      <div className="container">
        <h1 className="title">{t("ourproducts")}</h1>
        <hr />
        <div className="row">
          <div className="col-lg-3 category_web">
            <h1>{t("category")}</h1>
            <hr />
            <ul>
              <li>
                <span id="0" onClick={handleCategory}>
                  {t("allproducts")}
                </span>
              </li>
              {loadingCategories ? (
                <div className="loading">Loading...</div>
              ) : (
                dataCategories?.map((category) => {
                  return (
                    <li>
                      <span id={category?.id} onClick={handleCategory}>
                        {
                          category?.details?.filter((detail) => {
                            return detail?.languageId === currentLanguage;
                          })[0]?.name
                        }
                      </span>
                    </li>
                  );
                })
              )}
            </ul>
          </div>
          <div className="col-lg-9 col-md-12 col-sm-12 col-12">
            <div className="products_area">
              <div className="row g-4">
                {loadingProducts ? (
                  <Loading></Loading>
                ) : (
                  currentData?.map((product) => {
                    return (
                      <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                        <Productbox
                          key={product?.id}
                          id={product?.id}
                          imgurl={product?.imageUrl}
                          productname={
                            product?.details?.filter((detail) => {
                              return detail?.languageId === currentLanguage;
                            })[0]?.name
                          }
                        />
                      </div>
                    );
                  })
                )}
              </div>
              <div className="pagination">{renderPageNumbers()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
