import React, { useState, useEffect } from "react";
import Newsbox from "../../Components/Newsbox/Newsbox";
import { useTranslation } from "react-i18next";
import "./News.css";
import Loading from "../../Components/Loading/Loading";
import { Headchanger } from "../../utils/Headchanger";

const News = () => {
  const { t, i18n } = useTranslation();
  const [index, setIndex] = useState(4);
  const [dataNews, setDataNews] = useState([]);
  const [loadingNews, setLoadingNews] = useState(true);

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  Headchanger("Foodini - " + t("news"), t("news"));

  let currentLanguage = 1;

  if (i18n.language === "az") {
    currentLanguage = 1;
  } else if (i18n.language === "ru") {
    currentLanguage = 2;
  }

  useEffect(() => {
    fetch(`https://foodini.az/api/News/GetAllNews`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response error");
        }
        return response.json();
      })
      .then((responsedata) => {
        setDataNews(responsedata.data);
        setLoadingNews(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingNews(false);
      });
  }, []);

  const dataforRender = dataNews.slice(0, index);

  const LoadMore = () => {
    if (index + 4 >= dataNews.length) {
      setIndex(dataNews.length);
    } else {
      setIndex(index + 4);
    }
    dataNews.slice(0, index);
  };

  return (
    <div className="news_page">
      <div className="container">
        <h1 className="title_section">{t("news")}</h1>
        <hr />
        <div className="row g-4">
          {loadingNews ? (
            <Loading></Loading>
          ) : (
            dataforRender?.map((news) => {
              return (
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                  <Newsbox
                    key={news?.id}
                    id={news?.id}
                    images={news?.images}
                    title={
                      news?.details?.filter((detail) => {
                        return detail?.languageId === currentLanguage;
                      })[0]?.title
                    }
                    content={
                      news?.details?.filter((detail) => {
                        return detail?.languageId === currentLanguage;
                      })[0]?.description
                    }
                  />
                </div>
              );
            })
          )}
          {dataNews && dataNews.length <= index ? (
            <div></div>
          ) : (
            <button type="button" onClick={LoadMore} className="loadmore">
              {t("loadmore")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default News;
