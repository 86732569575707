import React from "react";
import "./Partnerbox.css";
import { Link } from "react-router-dom";

const Partnerbox = ({ id, name, logourl }) => {
  return (
    <div className="company-logo-box" id={id}>
      <Link to={`/partner/${id}`}>
        {logourl === null ? (
          ""
        ) : (
          <img
            src={`/Images/Partners/${logourl}`}
          // src={`https://firebasestorage.googleapis.com/v0/b/foodini-1b09b.appspot.com/o/Images%2FPartners%2F${logourl}?alt=media&token=3bc509a1-691e-40c3-a83c-fd19c721fef7`}
            alt="Company Logo"
            className="logo-image"
          />
        )}
        <h2 className="company-name">{name}</h2>
      </Link>
    </div>
  );
};

export default Partnerbox;
