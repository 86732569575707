import React, { useEffect, useState } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "./Firebase";
import { v4 } from "uuid";

const Mediafiles = () => {
  const [imgList, setImgList] = useState([]);

  const imgListref = ref(storage, "/Images/Posters");

  useEffect(() => {
    listAll(imgListref).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((downloadURL) => {
          setImgList((prev) => [...prev, downloadURL]);
        });
      });
    });
  }, []);

  return (
    <div className="">
      {imgList.map((img) => {
        return <img src={img} alt="" />;
      })}
      {console.log(imgList)}
    </div>
  );
};

export default Mediafiles;
