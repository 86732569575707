import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDw_kw8Xm_b3ixBacWOAKCmPxwPC8rJNaE",
  authDomain: "foodini-1b09b.firebaseapp.com",
  projectId: "foodini-1b09b",
  storageBucket: "foodini-1b09b.appspot.com",
  messagingSenderId: "300787066064",
  appId: "1:300787066064:web:5804f012da8c1f976baad5",
  measurementId: "G-SKY453YYW3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);