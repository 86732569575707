import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import "./Newsspecialpage.css";
import { Headchanger } from "../../utils/Headchanger";

const Newsspecialpage = () => {
  const { t, i18n } = useTranslation();
  const [dataNews, setDataNews] = useState();
  const [loadingNews, setLoadingNews] = useState(true);
  const [currentImage, setCurrentImage] = useState("");
  const { newsId } = useParams();

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  let currentLanguage = 1;
  if (i18n.language === "az") {
    currentLanguage = 1;
  } else if (i18n.language === "ru") {
    currentLanguage = 2;
  }

  useEffect(() => {
    if (newsId >= 0) {
      fetch(`https://foodini.az/api/News/${newsId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Response error");
          }

          return response.json();
        })
        .then((responsedata) => {
          setCurrentImage(`/Images/News/${responsedata.data.images[0].name}`);
          // {`https://firebasestorage.googleapis.com/v0/b/foodini-1b09b.appspot.com/o/Images%2FNews%2F${responsedata.data.images[0].name}?alt=media&token=0aeffc0e-f97b-420d-8d43-fffac1cb8654`}
          setDataNews(responsedata.data);
          setLoadingNews(false);
          Headchanger(
            "Foodini - " +
              responsedata?.data?.filter((item) => {
                return item?.languageId === currentLanguage;
              })[0]?.title,
            responsedata?.data?.details?.filter((item) => {
              return item?.languageId === currentLanguage;
            })[0]?.description?.slice(0, 150)
          );
        })
        .catch((error) => {
          console.error(error.mesagge);
          setLoadingNews(false);
        });
    }
  }, [newsId, currentLanguage]);

  const handleImage = (event) => {
    setCurrentImage(event.currentTarget.src);
  };

  return (
    <div className="news_page_special">
      {loadingNews && newsId > 0 ? (
        <Loading></Loading>
      ) : (
        <div className="container">
          <h1>
            {
              dataNews?.details?.filter((item) => {
                return item?.languageId === currentLanguage;
              })[0]?.title
            }
          </h1>
          <hr />
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="left_side">
                <img src={currentImage} alt="news" className="top_img" />
                <div className="img_list">
                  {dataNews?.images?.slice(0, 3).map((image) => {
                    return (
                      <img
                        src={`/Images/News/${image.name}`}
                        // src={`https://firebasestorage.googleapis.com/v0/b/foodini-1b09b.appspot.com/o/Images%2FNews%2F${image?.name}?alt=media&token=0aeffc0e-f97b-420d-8d43-fffac1cb8654`}
                        alt={image?.name}
                        id={image?.id}
                        onClick={handleImage}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="right_side">
                <p>
                  {
                    dataNews?.details?.filter((item) => {
                      return item?.languageId === currentLanguage;
                    })[0]?.description
                  }
                </p>
                <div className="date_news">
                  {t("time")}:{" "}
                  <span>{String(dataNews?.dateTime).slice(0, 10)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Newsspecialpage;
