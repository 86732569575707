import React, { useEffect, useState } from "react";
import "./Header.css";
import "bootstrap/dist/css/bootstrap.css";
import { BsSearch } from "react-icons/bs";
import { HiMenu } from "react-icons/hi";
import { GrClose } from "react-icons/gr";
import { Link } from "react-router-dom";
import LogoPng from "../..//Assets/images/Logo.svg";
import "./Header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";

const HeaderComp = () => {
  const [activeAz, setActiveAz] = useState("active");
  const [activeRu, setActiveRu] = useState("");
  const [searchText, setsearchText] = useState("");
  const [inmputHolder, setInputHolder] = useState("");
  const { t, i18n } = useTranslation();
  const [dataProducts, setDataProducts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  let currentLanguage = 1;
  if (i18n.language === "az") {
    currentLanguage = 1;
  } else if (i18n.language === "ru") {
    currentLanguage = 2;
  }

  useEffect(() => {
    fetch(`https://foodini.az/api/Product/GetAllProducts`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response error");
        }
        return response.json();
      })
      .then((responsedata) => {
        setDataProducts(responsedata.data);
      })
      .catch((error) => {
        console.error(error.mesagge);
      });
  }, []);

  const handleAz = () => {
    setActiveAz("active");
    setActiveRu("");
    i18n.changeLanguage("az");
  };

  const handleRu = () => {
    setActiveAz("");
    setActiveRu("active");
    i18n.changeLanguage("ru");
  };

  const handleSearchText = (e) => {
    e.preventDefault();
    const results = dataProducts.filter((product) => {
      return product?.details?.some((detail) =>
        detail.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setSearchResults(results);
    setsearchText(e.target.value);
    setInputHolder(e.target.value);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleClick = () => {
    setIsOpen(false);
    setsearchText("");
  };

  const handleInput = () => {
    setsearchText("");
  };

  return (
    <header>
      <div className="container">
        <div className="header_area">
          <div className="logo">
            <Link to="/">
              <img src={LogoPng} alt="" />
            </Link>
          </div>
          <nav>
            <ul>
              <li>
                <Link to="/">{t("main")}</Link>
              </li>
              <li>
                <Link to="/products">{t("products")}</Link>
              </li>
              <li>
                <Link to="/partners">{t("partners")}</Link>
              </li>
              <li>
                <Link to="/news">{t("news")}</Link>
              </li>
              <li>
                <Link to="/about">{t("about")}</Link>
              </li>
              <li>
                <Link to="/contact">{t("contact")}</Link>
              </li>
            </ul>
          </nav>
          <div className="search">
            <input
              type="text"
              className="search_input"
              placeholder={t("searchproduct")}
              onChange={handleSearchText}
              value={searchText}
            />
            {searchResults.length > 0 ? (
              <div
                className={`search_results ${searchText === "" ? "show" : ""}`}
              >
                <ul>
                  {searchResults?.map((item) => {
                    return (
                      <li>
                        <Link
                          to={`/product/${item.id}`}
                          key={item?.id}
                          onClick={handleClick}
                        >
                          <div className="result_img">
                            <img
                              src={`/Images/Products/${item?.imageUrl}`}
                              alt={item?.imageUrl}
                            />
                          </div>
                          <span>
                            {
                              item?.details?.filter((detail) => {
                                return detail?.languageId === currentLanguage;
                              })[0]?.name
                            }
                          </span>
                        </Link>
                        {/* id={item?.id} onClick={handleNaviage} */}
                        <hr />
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <div className="search_results show"></div>
            )}

            <Link to={`/products/${inmputHolder}`} onClick={handleInput}>
              <button className="search_Btn">
                <BsSearch />
              </button>
            </Link>
          </div>
          <div className="languages">
            <span className={"az_language " + activeAz} onClick={handleAz}>
              AZ
            </span>
            <span className={"ru_language " + activeRu} onClick={handleRu}>
              RU
            </span>
          </div>
          <div className="sidebar_menu_icon">
            <HiMenu
              onClick={toggleSidebar}
              className={isOpen ? "activeiconsidebar" : ""}
            />
          </div>
        </div>
        <div className={isOpen ? "sidebar open" : "sidebar"}>
          <div className="sidebar_top">
            <div className="language_sidebar">
              <div className="languages_sidebar">
                <span className={"az_language " + activeAz} onClick={handleAz}>
                  AZ
                </span>
                <span className={"ru_language " + activeRu} onClick={handleRu}>
                  RU
                </span>
              </div>
            </div>
            <div className="close_sidebar">
              <GrClose onClick={toggleSidebar} />
            </div>
          </div>
          <hr />
          <ul className="menu">
            <li>
              <Link to="/" onClick={toggleSidebar}>
                {t("main")}
              </Link>
            </li>
            <li>
              <Link to="/products" onClick={toggleSidebar}>
                {t("products")}
              </Link>
            </li>
            <li>
              <Link to="/partners" onClick={toggleSidebar}>
                {t("partners")}
              </Link>
            </li>
            <li>
              <Link to="/news" onClick={toggleSidebar}>
                {t("news")}
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={toggleSidebar}>
                {t("about")}
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toggleSidebar}>
                {t("contact")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default HeaderComp;
