import React from "react";
import Logo from "../..//Assets/images/Logo.jpg";
import { useTranslation } from "react-i18next";
import "./About.css";
import { Headchanger } from "../../utils/Headchanger"

const About = () => {
  const { t } = useTranslation();
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  Headchanger('Foodini - ' + t("about"), t("aboutFoodini")?.slice(0,150) )
  
  return (
    <div className="about_page">
      <div className="container">
        <h1>FOODINI MMC</h1>
        <div className="row">
          <div className="col-lg-5">
            <img src={Logo} alt="Foodini_Logo" />
          </div>
          <div className="col-lg-7">
            <p>{t("aboutFoodini")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
