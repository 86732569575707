
export const Headchanger = (newTitle,newDescription) => {
  return (
    document.title = newTitle,
    document.querySelector('meta[name="description"]').setAttribute('content', newDescription),
    document.querySelector('meta[property="og:title"]').setAttribute('content', newTitle)
    // document.querySelector('meta[property="og:description"]').setAttribute('content', newDescription)
  )
}

