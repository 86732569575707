import "./App.css";
import { BrowserRouter, Route, Routes} from "react-router-dom";
import HeaderComp from "./Layout/Header/HeaderComp";
import FooterComp from "./Layout/Footer/FooterComp";
import Home from "./Pages/Home/Home";
import Products from "./Pages/Products/Products";
import Partners from "./Pages/Partners/Partners";
import News from "./Pages/News/News";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import NotFound from "./Pages/NotFound";
import Newsspecialpage from "./Pages/Newsspecialpage/Newsspecialpage";
import Product from "./Pages/Product/Product";
import Partner from "./Pages/Partner/Partner";
import Mediafiles from "./Mediafiles";
import Pagesearch from "./Pages/Pagesearch/Pagesearch";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <HeaderComp />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/news" element={<News />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/news/:newsId" element={<Newsspecialpage />} />
          <Route path="/product/:productId" element={<Product />} />
          <Route path="/partner/:partnerId" element={<Partner />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/media" element={<Mediafiles />} />
          <Route path="/products/:searchtext" element={<Pagesearch />} />
        </Routes>
        <FooterComp />
      </BrowserRouter>
    </div>
  );
}

export default App;
