import React, { useState, useEffect } from "react";
import "./Partners.css";
import Partnerbox from "../../Components/Partnerbox/Partnerbox";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading/Loading";
import { Headchanger } from "../../utils/Headchanger";

const Partners = () => {
  const { t, i18n } = useTranslation();
  const [dataPartners, setDataPartners] = useState();
  const [loadingPartners, setLoadingPartners] = useState(true);

  let currentLanguage = 1;
  if (i18n.language === "az") {
    currentLanguage = 1;
  } else if (i18n.language === "ru") {
    currentLanguage = 2;
  }

  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  Headchanger('Foodini - ' + t("partners"), t("partners") );

  useEffect(() => {
    fetch(`https://foodini.az/api/Partner/GetAllPartners`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response error");
        }
        return response.json();
      })
      .then((responsedata) => {
        setDataPartners(responsedata.data);
        setLoadingPartners(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingPartners(false);
      });
  }, []);

  return (
    <div className="partners_page">
      {loadingPartners ? (
        <Loading></Loading>
      ) : (
        <div className="container">
          <h1 className="title_section">{t("partners")}</h1>
          <hr />
          <div className="row g-4">
            {dataPartners?.map((partner) => {
              return (
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
                  <Partnerbox
                    key={partner?.id}
                    id={partner?.id}
                    name={
                      partner?.details?.filter((detail) => {
                        return detail?.languageId === Number(currentLanguage);
                      })[0]?.name
                    }
                    logourl={partner?.logoUrl}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Partners;
