import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import "./Partner.css";
import Loading from "../../Components/Loading/Loading";
import Productbox from "../../Components/Productbox/Productbox";
import { Headchanger } from "../../utils/Headchanger";

const Partner = () => {
  const { t, i18n } = useTranslation();
  const [dataPartner, setDataPartner] = useState([]);
  const [loadingPartner, setLoadingPartner] = useState(true);
  const { partnerId } = useParams();

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  let currentLanguage = 1;
  if (i18n.language === "az") {
    currentLanguage = 1;
  } else if (i18n.language === "ru") {
    currentLanguage = 2;
  }

  useEffect(() => {
    if (partnerId >= 0) {
      fetch(`https://foodini.az/api/Partner/${partnerId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Response error");
          }
          return response.json();
        })
        .then((responsedata) => {
          setDataPartner(responsedata.data);
          setLoadingPartner(false);
          Headchanger(
            "Foodini - " +
              responsedata?.data?.details?.filter(
                (item) => item?.languageId === currentLanguage
              )[0]?.name,
            responsedata?.data?.details?.filter((item) => {
              return item?.languageId === currentLanguage;
            })[0]?.description?.slice(0, 150)
          );
        })
        .catch((error) => {
          console.error(error.mesagge);
          setLoadingPartner(false);
        });
    }
  }, [partnerId, currentLanguage]);

  return (
    <div className="partner_single_page" id={dataPartner?.id}>
      {loadingPartner && partnerId >= 0 ? (
        <Loading></Loading>
      ) : (
        <div className="container">
          <h1>
            {
              dataPartner?.details?.filter((item) => {
                return item?.languageId === currentLanguage;
              })[0]?.name
            }
          </h1>
          <hr />
          <div className="row g-4">
            <div className="col-12">
              <div className="logo_partner">
                {dataPartner?.logourl == null ? (
                  ""
                ) : (
                  <img
                    src={`/Images/Parters/${dataPartner?.logoUrl}`}
                    // src={`https://firebasestorage.googleapis.com/v0/b/foodini-1b09b.appspot.com/o/Images%2FPartners%2F${dataPartner.logoUrl}?alt=media&token=3bc509a1-691e-40c3-a83c-fd19c721fef7`}
                    alt={
                      dataPartner?.details?.filter((item) => {
                        return item?.languageId === currentLanguage;
                      })[0]?.name
                    }
                  />
                )}
              </div>
              <div className="col-12">
                <div className="partner_contetnt">
                  <h2>
                    {t("country")}:
                    <span>
                      {
                        dataPartner?.details?.filter((item) => {
                          return item?.languageId === currentLanguage;
                        })[0]?.country
                      }
                    </span>
                  </h2>
                  <p>
                    {
                      dataPartner?.details?.filter((item) => {
                        return item?.languageId === currentLanguage;
                      })[0]?.description
                    }
                  </p>
                </div>
              </div>
              <div className="partner_products">
                <div className="row g-4">
                  {dataPartner?.products?.length === 0 ? (
                    <div></div>
                  ) : (
                    dataPartner?.products?.map((item) => {
                      return (
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                          <Link to={`/product/${item?.id}`}>
                            <Productbox
                              key={item?.id}
                              id={item?.id}
                              imgurl={item?.imageUrl}
                              productname={
                                item?.details?.filter((detail) => {
                                  return detail?.languageId === currentLanguage;
                                })[0]?.name
                              }
                            />
                          </Link>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Partner;
