import React from "react";
import "./Contact.css";
import { FaAddressCard, FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Headchanger } from "../../utils/Headchanger";

const Contact = () => {
  const { t } = useTranslation();
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  Headchanger(
    "Foodini - " + t("contact"),
    t("adress") + " , " + t("mail") + " , " + t("tel")
  );

  return (
    <div className="contact_page">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="contact_details">
              <ul>
                <li>
                  <FaAddressCard style={{ color: "#26007e" }} /> : {t("adress")}
                </li>
                <li>
                  <MdEmail style={{ color: "#26007e" }} /> :{" "}
                  <a href="mailto:  office.foodini@gmail.com">{t("mail")}</a>
                </li>
                <li>
                  <FaPhone style={{ color: "#26007e" }} /> :{" "}
                  <a href="tel:050-978-7326">{t("tel")} </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <iframe
              title="Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1805.5499014641907!2d49.73223578740609!3d40.440721418477565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x403085829f67642b%3A0x9ed6e9321584c6b9!2sFoodini%20MMC!5e0!3m2!1sen!2saz!4v1693436030293!5m2!1sen!2saz"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
