import React, { useState } from "react";
import "./Newsbox.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Newsbox = (props) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(props?.images[0]?.name);

  return (
    <div className="card_news">
      <div className="card-image">
        <img
        src={`/Images/News/${selectedImage}`}
          // src={`https://firebasestorage.googleapis.com/v0/b/foodini-1b09b.appspot.com/o/Images%2FNews%2F${selectedImage}?alt=media&token=0aeffc0e-f97b-420d-8d43-fffac1cb8654`}
          alt={selectedImage}
        />
      </div>
      <div className="card_right">
        <div className="card-content">
          <h2>{props?.title}</h2>
          <p>
            {props?.content?.slice(0, 90) + "..."}
            <Link to={`/news/${props?.id}`}>{t("more")}</Link>
          </p>
        </div>
        <div className="thumbnail-container">
          {props?.images?.slice(0, 3)?.map((image, index) => (
            <img
              key={index}
              src={`/Images/News/${image?.name}`}
              // src={`https://firebasestorage.googleapis.com/v0/b/foodini-1b09b.appspot.com/o/Images%2FNews%2F${image?.name}?alt=media&token=0aeffc0e-f97b-420d-8d43-fffac1cb8654`}
              alt={image?.name}
              className={`thumbnail ${
                selectedImage === image?.name ? "selected" : ""
              }`}
              onClick={() => setSelectedImage(image?.name)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Newsbox;
