import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { BsLinkedin, BsFacebook, BsInstagram } from "react-icons/bs";
import { FaAddressCard, FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const FooterComp = () => {
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [textarea, setTextarea] = useState("");
  const { t } = useTranslation();

  const handleNameChange = (event) => {
    setNameInput(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailInput(event.target.value);
  };

  const handleTextChange = (event) => {
    setTextarea(event.target.value);
  };

  const sendMessage = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    
    fetch("https://foodini.az/api/Message/CreateMessage", {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
      },
      // body: '{\n  "name": "string",\n  "email": "user@example.com",\n  "text": "stringstri"\n}',
      body: JSON.stringify({
        name: String(nameInput),
        email: String(emailInput),
        text: String(textarea),
      }),
    });

    setNameInput("");
    setEmailInput("");
    setTextarea("");
  };

  return (
    <footer>
      <div className="top_footer">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-3 col-md-5 col-sm-12">
              <div className="about_us_footer">
                <h1>{t("about")}</h1>
                <p>
                  {t("aboutFoodini").slice(0,199)}
                  <Link to="/about">{t("more")}</Link>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="contact_footer">
                <h1>{t("contact")}</h1>
                <ul>
                  <li>
                    <FaAddressCard style={{ color: "#26007e" }} /> :{" "}
                    {t("adress")}
                  </li>
                  <li>
                    <MdEmail style={{ color: "#26007e" }} /> :{" "}
                    <a href="mailto:  office.foodini@gmail.com">{t("mail")}</a>
                  </li>
                  <li>
                    <FaPhone style={{ color: "#26007e" }} /> :{" "}
                    <a href="tel:050-978-7326">{t("tel")} </a>
                  </li>
                </ul>
              </div>
              <hr />
              <div className="social_network">
                <a href="https://www.linkedin.com/company/foodinimmc?originalSubdomain=az">
                  <BsLinkedin />
                </a>
                <a href="https://www.facebook.com/foodini.az">
                  <BsFacebook />
                </a>
                <a href="https://www.instagram.com/foodini_mmc/">
                  <BsInstagram />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <form className="ask_me">
                <h1>{t("contactus")}</h1>
                <input
                  type="text"
                  placeholder={t("contactusname")}
                  required
                  value={nameInput}
                  onChange={handleNameChange}
                />
                <input
                  type="email"
                  placeholder={t("contactusmail")}
                  required
                  onChange={handleEmailChange}
                  value={emailInput}
                />
                <textarea
                  required
                  placeholder={t("contactustext")}
                  name="question"
                  className="questionarea"
                  cols="30"
                  rows="5"
                  onChange={handleTextChange}
                  value={textarea}
                ></textarea>
                <button
                  className="Send_question"
                  type="button"
                  onClick={sendMessage}
                >
                  {t("send")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrigt_footer">
        <div className="container">© CopyRight 2023 Foodini MMC</div>
      </div>
    </footer>
  );
};

export default FooterComp;
