import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files

import translationAZ from "./az.json";
import translationRU from "./ru.json";

//Creating object with the variables of imported translation files
const resources = {
  az: {
    translation: translationAZ,
  },
  ru: {
    translation: translationRU,
  },
};

//i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng:"az", //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;