import React, { useState, useEffect } from "react";
import Loading from "../../Components/Loading/Loading";
import Productbox from "../../Components/Productbox/Productbox";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "./Pagesearch.css";
import { Headchanger } from "../../utils/Headchanger";

const SearchPage = () => {
  const { t, i18n } = useTranslation();
  const [dataProducts, setDataProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);

  const { searchtext } = useParams();

  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  Headchanger(
    "Foodini - " + t("seacherProducts"), t("seacherProducts")
  );

  let currentLanguage = 1;
  if (i18n.language === "az") {
    currentLanguage = 1;
  } else if (i18n.language === "ru") {
    currentLanguage = 2;
  }

  useEffect(() => {
    fetch(`https://foodini.az/api/Product/GetAllProducts`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response error");
        }
        return response.json();
      })
      .then((responedata) => {

        const results = responedata?.data?.filter((product) => {
          return product?.details?.some((detail) =>
            detail.name.toLowerCase().includes(searchtext.toLowerCase())
          );
        });

        setDataProducts(results);
        setLoadingProducts(false);
      })
      .catch((error) => {
        console.error(error.mesagge);
        setLoadingProducts(false);
      });
  }, [searchtext,currentLanguage]);

  return (
    <section className="product_section">
      <div className="container">
        <div className="row g-4">
          <h1 className="title_section">{t("seacherProducts")}</h1>
          <hr />
          {loadingProducts ? (
            <Loading></Loading>
          ) : (
            dataProducts?.map((product) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                  <Productbox
                    key={product?.id}
                    id={product?.id}
                    imgurl={product?.imageUrl}
                    productname={
                      product?.details?.filter((detail) => {
                        return detail?.languageId === currentLanguage;
                      })[0]?.name
                    }
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </section>
  );
};

export default SearchPage;
