import React from "react";
import "./Productbox.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Productbox = ({ imgurl, productname, id }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="productbox" id={id}>
        <div className="img_box">
          <img
            src={`/Images/Products/${imgurl}`}
            // src={`https://firebasestorage.googleapis.com/v0/b/foodini-1b09b.appspot.com/o/Images%2FProducts%2F${imgurl}?alt=media&token=8ab1394f-8a44-464a-aafc-12b68a815928`}
            alt={productname}
          />
        </div>
        <div className="card-content">
          <h2 className="wrap-text">{productname}</h2>
          <Link to={`/product/${id}`}>{t("more")}</Link>
        </div>
      </div>
    </div>
  );
};

export default Productbox;
