import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import "./Slider.css";

const Slider = ({ items }) => {
  const imageWrapperRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("right");

  const scrollSpeed = 1;

  useEffect(() => {
    const imageWrapper = imageWrapperRef.current;

    const scrollImages = () => {
      if (!isHovered && !isDragging) {
        if (scrollDirection === "right") {
          imageWrapper.scrollLeft += scrollSpeed;
          if (
            imageWrapper.scrollLeft >=
            imageWrapper.scrollWidth - imageWrapper.clientWidth
          ) {
            setScrollDirection("left");
          }
        } else if (scrollDirection === "left") {
          imageWrapper.scrollLeft -= scrollSpeed;
          if (imageWrapper.scrollLeft <= 0) {
            setScrollDirection("right");
          }
        }
      }
    };

    const interval = setInterval(scrollImages, 20);

    return () => {
      clearInterval(interval);
    };
  }, [isHovered, isDragging, scrollDirection]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - imageWrapperRef.current.offsetLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - imageWrapperRef.current.offsetLeft;
    const walk = (x - startX) * 0.05;
    imageWrapperRef.current.scrollLeft -= walk;
  };

  return (
    <div
      className="image-wrapper"
      ref={imageWrapperRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      {items?.map((item) => {
        return item.logoUrl === null ? (
          ""
        ) : (
          <div className="image-container">
            <Link to={`/partner/${item.id}`}>
              <img
                key={item.id}
                src={`/Images/Partners/${item?.logoUrl}`}
                // src={`https://firebasestorage.googleapis.com/v0/b/foodini-1b09b.appspot.com/o/Images%2FPartners%2F${item?.logoUrl}?alt=media&token=3bc509a1-691e-40c3-a83c-fd19c721fef7`}
                alt={item?.logoUrl}
                id={item?.id}
              />
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Slider;
