import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./Home.css";
import Productbox from "../../Components/Productbox/Productbox";
import Newsbox from "../../Components/Newsbox/Newsbox";
import Slider from "../../Components/Slider/Slider";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading/Loading";
import { Headchanger } from "../../utils/Headchanger";

const Home = () => {
  const { t, i18n } = useTranslation();

  const [dataPosters, setDataPosters] = useState([]);
  const [loadingPosters, setLoadingPosters] = useState(true);
  const [dataProducts, setDataProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [dataNews, setDataNews] = useState([]);
  const [loadingNews, setLoadingNews] = useState(true);
  const [dataPartners, setDataPartners] = useState([]);
  const [loadingPartners, setLoadingPartners] = useState(true);

  let currentLanguage = 1;
  if (i18n.language === "az") {
    currentLanguage = 1;
  } else if (i18n.language === "ru") {
    currentLanguage = 2;
  }

  Headchanger('Foodini.az - ' + t("foodini"),t("descriptionMeta"));

  window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

  useEffect(() => {
    fetch(`https://foodini.az/api/Poster/GetAllPosters`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response error");
        }
        return response.json();
      })
      .then((data) => {
        setDataPosters(data.data);
        setLoadingPosters(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingPosters(false);
      });
  }, []);

  useEffect(() => {
    fetch(`https://foodini.az/api/Product/GetMainProducts?predicate=true`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response error");
        }
        return response.json();
      })
      .then((data) => {
        setDataProducts(data.data);
        setLoadingProducts(false);
      })
      .catch((error) => {
        setLoadingProducts(false);
      });
  }, []);

  useEffect(() => {
    fetch(`https://foodini.az/api/News/GetAllNews`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response error");
        }
        return response.json();
      })
      .then((data) => {
        setDataNews(data.data);
        setLoadingNews(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingNews(false);
      });
  }, []);

  useEffect(() => {
    fetch(`https://foodini.az/api/Partner/GetAllPartners`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response error");
        }
        return response.json();
      })
      .then((data) => {
        setDataPartners(data.data);
        setLoadingPartners(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingPartners(false);
      });
  }, []);

  return (
    <div className="homepage">
      <div className="container">
        <section className="poster_section">
          <Carousel interval={4000} touch={true} slide={true}>
            {loadingPosters ? (
              <Loading></Loading>
            ) : (
              dataPosters?.map((item) => {
                return (
                  <Carousel.Item>
                    <img
                      key={item?.id}
                      className="d-block w-100 vh-80 align-bottom "
                      src={`/Images/Posters/${item.imageUrl}`}
                      alt={"slide" + item?.id}
                    />
                    {/* {`https://firebasestorage.googleapis.com/v0/b/foodini-1b09b.appspot.com/o/Images%2FPosters%2F${item?.imageUrl}?alt=media&token=a24af634-713e-43f1-96b0-dad2da2ab47b`} */}
                    <Carousel.Caption
                      key={
                        item?.details?.filter((detail) => {
                          return detail?.languageId === currentLanguage;
                        })[0]?.posterDetailId
                      }
                    >
                      <h5>
                        {
                          item?.details?.filter((detail) => {
                            return detail?.languageId === currentLanguage;
                          })[0]?.title
                        }
                      </h5>
                      <p>
                        {
                          item?.details?.filter((detail) => {
                            return detail?.languageId === currentLanguage;
                          })[0]?.description
                        }
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })
            )}
          </Carousel>
        </section>
      </div>
      <section className="product_section">
        <div className="container">
          <div className="row g-4">
            <h1 className="title_section">{t("ourproducts")}</h1>
            <hr />
            {loadingProducts ? (
              <Loading></Loading>
            ) : (
              dataProducts
                ?.slice(0, 8)
                .sort((a, b) => a.details[0]?.country - b.details[0]?.country)
                .map((product) => {
                  return (
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                      <Productbox
                        key={product?.id}
                        id={product?.id}
                        imgurl={product?.imageUrl}
                        productname={
                          product?.details?.filter((detail) => {
                            return detail?.languageId === currentLanguage;
                          })[0]?.name
                        }
                      />
                    </div>
                  );
                })
            )}
          </div>
        </div>
      </section>
      {dataNews.length === 0 ? (
        ""
      ) : (
        <section className="news_section">
          <div className="container">
            <h1 className="title_section">{t("news")}</h1>
            <hr />
            <div className="row">
              {loadingNews ? (
                <Loading></Loading>
              ) : (
                dataNews?.slice(0, 2).map((news) => {
                  return (
                    <div className="col-lg-6 col-md-12 col-12">
                      <Newsbox
                        key={news?.id}
                        id={news?.id}
                        images={news?.images}
                        title={
                          news?.details?.filter((detail) => {
                            return detail?.languageId === currentLanguage;
                          })[0]?.title
                        }
                        content={
                          news?.details?.filter((detail) => {
                            return detail?.languageId === currentLanguage;
                          })[0]?.description
                        }
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </section>
      )}
      <section className="partners_section">
        <div className="container">
          <h1 className="title_section">{t("partners")}</h1>
          <hr />
          {loadingPartners ? (
            <Loading></Loading>
          ) : (
            <Slider items={dataPartners} />
          )}
        </div>
      </section>
    </div>
  );
};

export default Home;
