import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Product.css";
import Loading from "../../Components/Loading/Loading";
import { Headchanger } from "../../utils/Headchanger";

const Product = () => {
  const { t, i18n } = useTranslation();
  const [dataProduct, setDataProduct] = useState();
  const [loadingProduct, setLoadingProduct] = useState(true);
  const { productId } = useParams();

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  let currentLanguage = 1;
  if (i18n.language === "az") {
    currentLanguage = 1;
  } else if (i18n.language === "ru") {
    currentLanguage = 2;
  }

  useEffect(() => {
    if (productId >= 0) {
      fetch(`https://foodini.az/api/Product/${productId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Response error");
          }
          return response.json();
        })
        .then((responsedata) => {
          setDataProduct(responsedata.data);
          setLoadingProduct(false);
          Headchanger(
            "Foodini - " +
              responsedata?.data?.details?.filter((item) => {
                return item?.languageId === currentLanguage;
              })[0]?.name,
            responsedata?.data?.details?.filter(
              (item) => item?.languageId === currentLanguage
            )[0]?.description?.slice(0, 150)
          );
        })
        .catch((error) => {
          console.error(error.mesagge);
          setLoadingProduct(false);
        });
    }
  }, [productId, currentLanguage]);

  return (
    <div className="product_single_page">
      {loadingProduct && productId >= 0 ? (
        <Loading></Loading>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="product_image">
                <img
                  src={`/Images/Products/${dataProduct?.imageUrl}`}
                  // src={`https://firebasestorage.googleapis.com/v0/b/foodini-1b09b.appspot.com/o/Images%2FProducts%2F${dataProduct?.imageUrl}?alt=media&token=8ab1394f-8a44-464a-aafc-12b68a815928`}
                  alt={dataProduct?.imageUrl}
                />
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="product_content">
                <h1>
                  <span>{t("productname")}: </span>
                  {
                    dataProduct?.details?.filter(
                      (item) => item?.languageId === currentLanguage
                    )[0]?.name
                  }
                </h1>
                <h1>
                  <span>{t("productkategory")}: </span>
                  {
                    dataProduct?.category?.details?.filter((item) => {
                      return item?.languageId === currentLanguage;
                    })[0]?.name
                  }
                </h1>
                <h1>
                  <span>{t("productmanufacture")}: </span>
                  {
                    dataProduct?.partner?.details?.filter(
                      (item) => item?.languageId === currentLanguage
                    )[0]?.name
                  }
                </h1>
                <h1>
                  <span>{t("productcountry")}: </span>
                  {
                    dataProduct?.partner?.details?.filter(
                      (item) => item?.languageId === currentLanguage
                    )[0]?.country
                  }
                </h1>
                <p>
                  {
                    dataProduct?.details?.filter(
                      (item) => item?.languageId === currentLanguage
                    )[0]?.description
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
